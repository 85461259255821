<template>
  <div>
    <!-- Page header with icon and title -->
    <PageHeader :icon="'ri-vip-crown-line h2'" :title="title" :items="items" />
    <!-- Container for subscription plan cards -->
    <div>
      <button class="btn btn-sm btn-primary" @click="openAddUserRoleModal">
        Add Credits
      </button>
      <div class="mt-3">
        <b-row class="justify-content-center">
          <b-col cols="12" md="6">
            <Table :key="rerender.length" />
          </b-col>
        </b-row>
      </div>
    </div>
    <div>
      <div class="my-4 text-center">
        <b-modal
          id="modal-standard"
          ref="addUserRoleModel"
          title="Add Credits"
          title-class="font-18"
          hide-footer
        >
          <div class="row">
            <div class="col-lg-12">
              <form
                class="form-horizontal"
                @submit.prevent="addCreditsHandler"
                ref="formAddUserRole"
              >
                <div class="form-group">
                  <label for="users">Credits count</label>
                  <multiselect
                    name="updatef_projects"
                    id="updatef_projects"
                    v-model="creditsList"
                    tag-placeholder="Add credit"
                    placeholder="Add multiple credits"
                    label="text"
                    track-by="value"
                    :multiple="true"
                    :max-height="150"
                    :taggable="true"
                    v-validate="'required'"
                    @tag="addCreditsToList"
                    :options="[]"
                  />
                </div>

                <div class="text-center">
                  <!-- Buttons to submit or cancel the form -->
                  <button
                    class="btn btn-sm btn-primary w-md waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="reset"
                    class="btn btn-sm btn-light w-md waves-effect waves-light"
                    v-on:click="closeAddUserRoleModel()"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<style scoped>
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}
.custom-shadow:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 23;
}
.custom-card {
  /* margin-bottom: 20px; */
  text-align: center;
  padding-right: 0px;
  padding-left: 0px;
}
.ri-stack-fill:before,
.ri-loader-line,
.ri-star-line,
.ri-building-line {
  color: #0f3460;
  font-size: 90px;
}
@media (max-width: 575.98px) {
  .custom-card {
    margin-right: 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .custom-card {
    margin-right: 5px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .custom-card {
    margin-right: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .custom-card {
    margin-right: 15px;
  }
}
</style>
<script>
import PageHeader from "@/components/pageheader";
import { eventBus } from "../../../main";
import Multiselect from "vue-multiselect";
import Table from "./table.vue";

export default {
  components: {
    PageHeader,
    Multiselect,
    Table,
  },
  data() {
    return {
      title: "Subscription",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Subscription",
          active: true,
        },
      ],

      modalShow: false,
      id: null,
      formData: {
        title: "",
        adminCount: null,
        userCount: null,
      },
      creditsList: [],
      rerender: [],
    };
  },
  mounted() {
    eventBus.$emit("update-sidebar", "menuitems.subscription.text");
    // if (Object.keys(this.$store.state.Auth.subPlanData).length) {
    //   this.subscriptionPlanData = this.$store.state.Auth.subPlanData;
    // } else {
    //   this.getSubscriptionPlans();
    // }
  },
  computed: {
    iconClass() {
      return (icon) => {
        switch (icon) {
          case "Basic":
            return "ri-stack-fill";
          case "Silver":
            return "ri-loader-line";
          case "Gold":
            return "ri-star-line";
          case "Enterprise":
            return "ri-building-line";
          default:
            return "";
        }
      };
    },
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },

    openAddUserRoleModal() {
      this.$refs["addUserRoleModel"].show();
    },
    closeAddUserRoleModel() {
      this.usernameList = [];
      this.$refs["addUserRoleModel"].hide();
    },
    addCreditsHandler() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      let data = {
        credits: this.creditsList.map((c) => c.value),
      };
      if (data.credits.length === 0) {
        this.messageToast(
          "Error",
          "danger",
          "Please enter credits count to add"
        );
        loader.hide();
        return;
      }
      this.$store.getters.client
        .post(`/serveradmin/credit-plans`, data)
        .then((response) => {
          this.closeAddUserRoleModel();
          this.creditsList = [];
          this.messageToast(
            "Success",
            "success",
            response.data.message || "Credits has been changed successfully"
          );
          this.getSubscriptionPlans();
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "Unable to change the credits plan. Please try again later."
          );
        });
    },
    addCreditsToList(credit) {
      if (
        credit &&
        !isNaN(credit.trim()) &&
        !this.creditsList.includes(credit)
      ) {
        this.creditsList.push({ text: credit, value: credit });
      }
    },
    async getSubscriptionPlans() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get("/serveradmin/credit-plans")
        .then((response) => {
          if (response.data.result) {
            this.$store.commit("setSubplanData", "");
            this.rerender = response.data.result;
          } else {
            this.messageToast("Error", "warning", "No credits found");
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "warning",
            "An error occurred while fetching credits"
          );
        });
    },
  },
};
</script>
