<template>
  <div class="card">
    <div class="card-body">
      <!-- <h4 class="card-title mb-4">Credits</h4> -->
      <div class="table-responsive">
        <b-table :items="subscriptionPlanData" :fields="fields" responsive="sm">
          <template #cell(isActive)="row">
            <div
              class="badge font-size-12"
              :class="{
                'badge-soft-danger': `${row.item.isActive}` === 'false',
                'badge-soft-success': `${row.item.isActive}` === 'true',
              }"
            >
              {{ row.item.isActive === true ? "Active" : "Deactive" }}
            </div>
          </template>
          <template #cell(action)="row">
            <div class="d-flex justify-content-center">
              <button
                class="btn btn-sm btn-primary"
                @click="editPlan(row.item)"
              >
                Edit
              </button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      id="editSubscription"
      v-model="modalShow"
      centered
      scrollable
      size="sm"
      hide-footer
      title="Edit Credits"
    >
      <form action="#" @submit.prevent="updatePlan()">
        <!-- Form fields for editing subscription plan -->
        <div class="form-group">
          <label>Credits <span class="text-danger">*</span></label>
          <input
            v-model="updatedCredit"
            type="number"
            class="form-control"
            name="updatedCredit"
            required
          />
        </div>

        <div class="form-group mb-0">
          <div class="text-center">
            <button type="submit" class="btn btn-primary btn-sm">
              Submit
            </button>
            <button type="reset" class="btn btn-secondary m-l-5 ml-1 btn-sm">
              Reset
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subscriptionPlanData: [],
      modalShow: false,
      updatedCredit: null,
      selectedPlan: null,
      fields: [
        { key: "credits", label: "Credits" },
        // { key: "isActive", label: "Status" },
        // { key: "action", label: "" },
      ],
    };
  },

  mounted() {
    if (Object.keys(this.$store.state.Auth.subPlanData).length) {
      this.subscriptionPlanData = this.$store.state.Auth.subPlanData;
    } else {
      this.getSubscriptionPlans();
    }
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    async getSubscriptionPlans() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get("/serveradmin/credit-plans")
        .then((response) => {
          if (response.data.result) {
            this.subscriptionPlanData = response.data.result;
            this.$store.commit("setSubplanData", this.subscriptionPlanData);
          } else {
            this.messageToast("Error", "warning", "No credits found");
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "warning",
            "An error occurred while fetching credits"
          );
        });
    },
    editPlan(data) {
      console.log(data);
      this.modalShow = true;
      this.selectedPlan = data;
      this.updatedCredit = data.credits;
    },
    updatePlan() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .patch(`/serveradmin/credit-plans/${this.selectedPlan._id}`, {
          credits: this.updatedCredit,
          isActive: true,
        })
        .then(() => {
          this.modalShow = false;
          this.messageToast(
            "Success",
            "success",
            "Credits has been changed successfully"
          );
          this.getSubscriptionPlans();
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "Unable to change the Credit. Please try again later."
          );
        });
    },
  },
};
</script>
<!-- <style scoped>
.card {
  max-height: 500px;
}
</style> -->
