<template>
  <div class="col-lg-12">
    <div class="d-flex justify-content-between align-items-center my-3">
      <!-- Display the title and export button -->
      <h4 class="card-title mb-0">ORGANIZATION DETAILS</h4>
      <!-- <button type="submit" class="btn btn-sm btn-primary" @click="getexcel">
        <span> Export to XLS</span>
      </button> -->
    </div>
    <div role="tablist" class="pb-2">
      <!-- Accordion for General Details -->
      <b-card no-body class="mb-1 shadow-none">
        <b-card-header header-tag="header" role="tab">
          <h6 class="m-0">
            <a
              v-b-toggle.accordion-1
              class="text-dark"
              href="javascript: void(0);"
              >General Details</a
            >
          </h6>
        </b-card-header>
        <b-collapse
          id="accordion-1"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <!-- Display general organization details -->
            <b-card-text
              ><b>Org ID:</b> {{ organization_details.id }}</b-card-text
            >
            <b-card-text
              ><b>Name:</b> {{ organization_details.name }}</b-card-text
            >
            <b-card-text
              ><b>Email:</b> {{ organization_details.email }}</b-card-text
            >
            <b-card-text
              ><b>Contact:</b>{{ organization_details.contact }}</b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>
      <!-- Accordion for Access Details -->
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" role="tab">
          <h6 class="m-0">
            <a
              v-b-toggle.accordion-2
              class="text-dark"
              href="javascript: void(0);"
              >Access Details</a
            >
          </h6>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <!-- Display access details -->
            <b-card-text
              ><b>Access URL:</b
              >{{ organization_details.accessURL }}</b-card-text
            >
            <b-card-text
              ><b>Org Github URL:</b
              >{{ organization_details.orgGithubURL }}</b-card-text
            >
            <b-card-text
              ><b>Custom Id:</b>{{ organization_details.customId }}</b-card-text
            >
          </b-card-body>
        </b-collapse>
      </b-card>
      <!-- Accordion for Other Details -->
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" role="tab">
          <h6 class="m-0">
            <a
              v-b-toggle.accordion-3
              class="text-dark"
              href="javascript: void(0);"
              >Other Details</a
            >
          </h6>
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="card-body">
                <!-- Display a list of other organization details -->
                <ul class="list-group">
                  <!-- DITA-OT Version -->
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <strong>DITA-OT Version</strong>
                    <span>{{ organization_details.ditaotVersion }}</span>
                  </li>
                  <!-- DocManager -->
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <strong>DocManager</strong>
                    <span>{{
                      organization_details.docManager ? "Yes" : "No"
                    }}</span>
                  </li>
                  <!-- DocEditor -->
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <strong>DocEditor</strong>
                    <span>{{
                      organization_details.editor ? "Yes" : "No"
                    }}</span>
                  </li>
                  <!-- DocPublisher -->
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <strong>DocPublisher</strong>
                    <span>{{
                      organization_details.publisher ? "Yes" : "No"
                    }}</span>
                  </li>
                  <!-- Active -->
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <strong>Active</strong>
                    <span>{{
                      organization_details.isActive ? "Yes" : "No"
                    }}</span>
                  </li>
                  <!-- Domain Verified -->
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <strong>Domain Verified</strong>
                    <span>{{
                      organization_details.isDomainVerified ? "Yes" : "No"
                    }}</span>
                  </li>
                  <!-- Email Verified -->
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <strong>Email Verified</strong>
                    <span>{{
                      organization_details.isEmailVerified ? "Yes" : "No"
                    }}</span>
                  </li>
                  <!-- Project Count -->
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <strong>Project Count</strong>
                    <span>{{ orgFullDetails.projectCount }}</span>
                  </li>
                  <!-- Collaborators Count -->
                  <!-- <li class="list-group-item d-flex justify-content-between align-items-center">
                                    <strong>Collaborators Count</strong>
                                    <span>{{ orgFullDetails.collaboratorsCount }}</span>
                                </li> -->
                  <!-- File Count -->
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <strong>File Count</strong>
                    <span>{{ orgFullDetails.fileCount }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>
<script>
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../../api/global.env";
export default {
  props: {
    orgFullDetails: {
      type: Object,
    },
    organization_details: {
      type: Object,
    },
  },
  data() {
    return {
      id: CryptoJS.AES.decrypt(this.$route.params.id, secretKey).toString(
        CryptoJS.enc.Utf8
      ),
    };
  },

  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    async getexcel() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`/serveradmin/organization/downloadxls/byorgid?orgId=${this.id}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.data instanceof Blob) {
            this.messageToast(
              "Success",
              "success",
              "Organization details Xls downloaded successfully."
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "OrgDetails.xlsx");
            document.body.appendChild(link);
            link.click();
          } else {
            this.messageToast(
              "Error",
              "danger",
              "Unable to download Organization details xls. Please try again later."
            );
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "An error occurred while downloading Organization details xls. Please try again later."
          );
        });
    },
  },
};
</script>
