<template>
  <div>
    <!-- Card for Recent Release Logs -->
    <div class="card">
      <div
        class="custom-notifications d-flex justify-content-between align-items-center"
      >
        <div class="custom-title">
          Recent Release Log
        </div>
        <div>
          <button
            class="btn  text-primary view-details-btn btn-sm m-0 "
            @click="viewMore"
          >
            <span class="mdi mdi-eye-outline mr-2"></span> View details
          </button>
        </div>
      </div>
      <div class="card-body  py-3">
        <div data-simplebar class="scrollable-content">
          <ul class="list-unstyled activity-wid">
            <!-- Skeleton Loader While Data is Loading -->
            <b-skeleton-table
              v-if="showSpinner"
              :rows="5"
              :columns="2"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
            <!-- Render Release Logs if Data is Available and Loading is Complete -->
            <div v-else-if="activityData.length > 0 && !showSpinner">
              <li
                class="activity-list publication-list-cust-cls"
                v-for="(data, index) in activityData"
                :key="index"
              >
                <div class="activity-icon avatar-xs">
                  <!-- Release Log Icon -->
                  <span
                    class="avatar-title bg-soft-primary text-primary rounded-circle font-size-24 avatar-title-cust-cls"
                  >
                    <i :class="`${data.icon}`"></i>
                  </span>
                </div>
                <div>
                  <div class="d-flex justify-content-between">
                    <div>
                      <!-- Release Log Title/Name -->
                      <h5 class="font-size-13 text-uppercase ptitle-cust-cls">
                        {{ data.release_name }}
                      </h5>
                    </div>
                    <div>
                      <!-- Author Information -->
                      <p class="text-muted mb-0 mr-5">
                        Author: {{ data.author }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <!-- File Format Information -->
                    <p class="text-muted mb-0">
                      File format: {{ data.tag_name }}
                    </p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div>
                      <!-- Release Log Timestamp -->
                      <p class="text-muted mb-0 mr-5 smaller-text">
                        {{ getTimeAgo(data.releaseAt) }}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </div>
            <!-- Placeholder for No Release Logs Available -->
            <li v-else>
              <div
                class="d-flex justify-content-center align-items-center m-0 p-0"
              >
                <div class="placeholder-content">
                  <img
                    src="../../../../assets/production.png"
                    class="placeholder-image"
                  />
                  <div class="placeholder-text">
                    There have been no recent releases.
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSpinner: true,
      activityData: [],
      orgId: this.$store.state.Auth.orgId,
    };
  },
  mounted() {
    this.getrecentlog();
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    viewMore() {
      this.$router.push({
        name: "Recentreleasetable",
      });
    },
    getTimeAgo(timestamp) {
      const now = new Date();
      const timeDiff = now.getTime() - new Date(timestamp).getTime();
      const minuteDiff = Math.floor(timeDiff / 60000); // 60000 milliseconds in a minute
      if (minuteDiff < 1) {
        return "just now";
      } else if (minuteDiff < 60) {
        return `${minuteDiff} minute${minuteDiff > 1 ? "s" : ""} ago`;
      } else {
        const hourDiff = Math.floor(minuteDiff / 60);
        if (hourDiff < 24) {
          return `${hourDiff} hour${hourDiff > 1 ? "s" : ""} ago`;
        } else {
          const dayDiff = Math.floor(hourDiff / 24);
          if (dayDiff < 30) {
            return `${dayDiff} day${dayDiff > 1 ? "s" : ""} ago`;
          } else {
            const monthDiff = Math.floor(dayDiff / 30);
            if (monthDiff < 12) {
              return `${monthDiff} month${monthDiff > 1 ? "s" : ""} ago`;
            } else {
              const yearDiff = Math.floor(monthDiff / 12);
              return `${yearDiff} year${yearDiff > 1 ? "s" : ""} ago`;
            }
          }
        }
      }
    },
    getrecentlog() {
      this.$store.getters.client
        .get("/serveradmin/organization/releasedetails")
        .then((response) => {
          this.showSpinner = false;
          const recentlogData = [];
          response.data.forEach((ele) => {
            recentlogData.push({
              release_name: ele.releaseTitle,
              icon: "ri-edit-2-fill",
              tag_name: ele.outputFormat,
              author: ele.releasedBy,
              releaseAt: ele.updatedAt,
            });
          });
          this.$store.commit("setRecentReleaseLog", response.data);
          this.activityData = recentlogData.slice(0, 4);
        })
        .catch(() => {
          this.showSpinner = false;
        });
    },
  },
};
</script>

<style scoped>
.card {
  height: 52vh;
  overflow: hidden;
}

.custom-notifications {
  width: Fill (614px);
  height: Hug (60px);
  padding: 14px;
  border: 0px, 0px, 0.3px, 0px;
  gap: 24px;
}

.custom-title {
  width: Fill (566px);
  height: Hug (12px);
  gap: 10px;
  font-size: 18px;
  font-weight: 500;

  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.view-details-btn {
  background-color: rgba(113, 165, 203, 0.2);
}

.ptitle-cust-cls {
  margin-bottom: 0;
}

.publication-card-body-cust-cls {
  padding: 0.25rem 1.25rem !important;
}

.publication-list-cust-cls {
  padding: 0 0 25px 30px !important;
}

.scrollable-content {
  height: 40vh;
}

.smaller-text {
  font-size: smaller;
}

.placeholder-content {
  text-align: center;
}

.placeholder-image {
  height: 200px;
}

.placeholder-text {
  color: #666;
  font-size: 16px;
  text-align: center;
}
</style>
