<template>
  <div>
    <PageHeader :icon="'ri-building-4-line h2'" :title="title" :items="items" />
    <div fluid="xl">
      <div class="org-details row mb-4 text-primary">
        <div class="col-md-6">
          <p class="mb-2 mt-3">Organization ID: {{ org.customId }}</p>
          <p>Users: {{ userList.length }}</p>
        </div>
      </div>

      <b-tabs
        justified
        nav-class="nav-tabs-custom"
        content-class="text-muted"
        class="btabs-height bg-white"
      >
        <b-tab title="Projects" active>
          <div class="mt-1">
            <Projects></Projects>
          </div>
        </b-tab>
        <b-tab title="Admin">
          <div class="mt-1">
            <Admin :adminDetails="org"></Admin>
          </div>
        </b-tab>
        <b-tab title="Users">
          <div class="mt-1">
            <Users> </Users>
          </div>
        </b-tab>
        <b-tab title="Resource">
          <div>
            <Summary></Summary>
          </div>
        </b-tab>
        <b-tab title="Details">
          <div class="mt-1">
            <Details
              :organization_details="org"
              :orgFullDetails="orgFullDetails"
            >
            </Details>
          </div>
        </b-tab>
        <b-tab title="Credit Details">
          <div class="mt-1">
            <CreditusageDetails />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import Users from "./components/Tabs/users.vue";
import Admin from "./components/Tabs/admin.vue";
import Summary from "./components/Tabs/summary.vue";
import Projects from "./components/Tabs/projects.vue";
import Details from "./components/Tabs/details.vue";
import PageHeader from "@/components/pageheader";
import { eventBus } from "../../../main";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../api/global.env";
import { BTabs, BTab } from "bootstrap-vue";
import CreditusageDetails from "./components/Tabs/CreditusageDetails.vue";
export default {
  name: "orgDetails",
  components: {
    Details,
    Users,
    Admin,
    Projects,
    Summary,
    PageHeader,
    BTabs,
    BTab,
    CreditusageDetails,
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
      ],
      id: null,
      org: {},
      startDate: null,
      endDate: null,
      variant: "success",
      userList: [],
      activeTab: "project",
      orgFullDetails: {},
    };
  },
  mounted() {
    eventBus.$emit("update-sidebar", "menuitems.organizations.text");
    this.getOrganizationData();
    this.getFullDetails();
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    async getFullDetails() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get(`/serveradmin/organization/details?orgId=${this.id}`)
        .then((response) => {
          if (response.data) {
            this.orgFullDetails = response.data;
          } else {
            this.messageToast(
              "Error",
              "warning",
              "No organization details found"
            );
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "warning",
            "An error occurred while fetching organization details"
          );
        });
    },
    async getOrganizationData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get(`/serveradmin/organization/byorgid?orgId=${this.id}`, {
          headers: {
            orgId: this.id,
          },
        })
        .then((response) => {
          if (
            response.data &&
            Array.isArray(response.data) &&
            response.data.length > 0
          ) {
            this.org = response.data[0];
            this.items.push({
              text:
                this.org.name.charAt(0).toUpperCase() + this.org.name.slice(1),
              active: true,
            });
            this.title =
              this.org.name.charAt(0).toUpperCase() + this.org.name.slice(1);

            this.$store.getters.client
              .get(`/orguser/byorgid?orgId=${this.id}`, {
                headers: {
                  orgId: this.id,
                },
              })
              .then((response) => {
                this.userList = response.data;
              });
          } else {
            this.messageToast("Error", "warning", "No organization data found");
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "warning",
            "An error occurred while fetching organization data"
          );
        });
    },
  },
  created() {
    if ("id" in this.$route.params) {
      this.id = CryptoJS.AES.decrypt(this.$route.params.id, secretKey).toString(
        CryptoJS.enc.Utf8
      );
    }
  },
};
</script>
<style lang="scss">
.plan {
  &:hover {
    opacity: 0.8;
  }
}

.org-details {
  h1,
  h5,
  p {
    line-height: 1.25;
    margin-bottom: 0;
  }

  h1 {
    font-weight: 600;
  }

  p {
    font-weight: 900;
  }
}

.tab-card {
  background: rgba(23, 37, 146, 0.85) !important;
  color: #fff;
  text-align: center;
  cursor: pointer;

  i {
    font-size: 20px;
  }

  h6 {
    justify-content: center;
  }
}

.tab-card.active {
  background-color: #1cbb8c !important;
}

.col-md-6.text {
  text-align: right;
}

.subscription-btn {
  width: 247px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}
</style>
