<template>
  <b-card>
    <div class="table-responsive mb-0">
      <b-table sticky-header :items="admins" :fields="fields" responsive="sm">
      </b-table>
    </div>
  </b-card>
</template>
<script>
import _ from "lodash";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../../api/global.env";
export default {
  props: {
    adminDetails: {
      type: Object,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
        },
        {
          key: "email",
        },
        {
          key: "contact",
        },
        {
          key: "isActive",
        },
      ],
      admins: [],
      id: CryptoJS.AES.decrypt(this.$route.params.id, secretKey).toString(
        CryptoJS.enc.Utf8
      ),
      formData: {
        orgId: "",
        email: "",
        password: "",
        name: "",
        isActive: false,
        contact: "",
        isChangePassword: false,
      },
    };
  },
  mounted() {
    this.getOrganizationAdmin();
  },

  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },

    async getOrganizationAdmin() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get(`/orgadmin/byorgid?orgId=${this.id}`, {
          headers: {
            orgId: this.id,
          },
        })
        .then((response) => {
          this.admins = response.data;
          loader.hide();
        })
        .catch(() => {
          loader.hide();
        });
    },
  },
  computed: {
    _() {
      return _;
    },
  },
  created() {
    if ("customId" in this.$route.params) {
      this.formData.orgId = this.customId;
    }
    this.formData.orgId = CryptoJS.AES.decrypt(
      this.$route.params.id,
      secretKey
    ).toString(CryptoJS.enc.Utf8);
  },
};
</script>
<style lang="scss">
h3 i,
h3 span,
h4 {
  color: #021559 !important;
  text-transform: uppercase;
  line-height: 1.25;
  margin-bottom: 0;
  font-weight: 600;
}
h4.mb-2 {
  font-weight: bold;
}
.project-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    font-size: 1.25rem;
    font-weight: 600;
    color: #505d69;
    padding: 1rem 0;
    border-bottom: 1px solid #74788d;
  }
}
.page-item.active .page-link {
  background: #006d5b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.page-item .page-link {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
button#show-btn {
  background-color: #0e0e87;
}
</style>
