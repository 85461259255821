<template>
  <div>
    <PageHeader :icon="'ri-dashboard-line h3'" :title="title" :items="items" />
    <b-row class="d-flex">
      <b-col cols="12" lg="6" md="6">
        <Notifications />
      </b-col>
      <b-col cols="12" lg="6" md="6"> <Logs /></b-col>
    </b-row>
    <div>
      <Table />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/pageheader";
import Logs from "./components/recentlogs.vue";
import Table from "./components/table.vue";
import Notifications from "./components/notifications.vue";
import { eventBus } from "../../../main";

export default {
  components: {
    Logs,
    PageHeader,
    Table,
    Notifications,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  mounted() {
    // Emit an event to update the sidebar with the specified text
    eventBus.$emit("update-sidebar", "menuitems.dashboard.text");
  },
};
</script>
