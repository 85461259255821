<template>
  <b-card>
    <div class="table-responsive mb-0">
      <div class="d-flex justify-content-between">
        <div>
          <!-- Display general organization details -->
          <b-card-text
            ><b>Total Credits:</b>
            {{ creditsDetails.totalCredits }}</b-card-text
          >
          <b-card-text
            ><b>Remaining Credits:</b>
            {{ creditsDetails.remainingCredits }}</b-card-text
          >
          <b-card-text
            ><b>Used Credits:</b> {{ creditsDetails.usedCredits }}</b-card-text
          >
        </div>
        <div>
          <div class="mb-3">
            <button
              class="btn btn-sm btn-primary"
              @click="openUpdateCreditModal"
            >
              Update Credits
            </button>
            <div>
              <div class="my-4 text-center">
                <b-modal
                  id="modal-standard"
                  ref="updateCreditModel"
                  title="Update Credits"
                  title-class="font-18"
                  hide-footer
                >
                  <div class="row">
                    <div class="col-lg-12">
                      <form
                        class="form-horizontal"
                        @submit.prevent="updateCreditsHandler"
                        ref="formAddUserRole"
                      >
                        <div class="form-group">
                          <label for="users">Credits count</label>
                          <select
                            class="form-control"
                            v-model="updatedCredit"
                            required
                            placeholder="Select DITA-OT version"
                          >
                            <option value="" disabled selected
                              >Select Credit</option
                            >
                            <option
                              v-for="(data, index) in subscriptionPlanData"
                              :key="index"
                              :value="data._id"
                            >
                              {{ data.credits }}
                            </option>
                          </select>
                        </div>

                        <div class="text-center">
                          <!-- Buttons to submit or cancel the form -->
                          <button
                            class="btn btn-sm btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="reset"
                            class="btn btn-sm btn-light w-md waves-effect waves-light"
                            v-on:click="closeUpdateCreditModal()"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div>
      <!-- Row for Pagination and Search -->
      <div class="row">
        <div class="col-12">
          <div class="row py-3">
            <!-- Per Page Dropdown for Entries -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-flex align-items-center"
                  ><span class="text-nowrap">Show entries</span>
                  <div class="col-md-3">
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <!-- Buefy Table Component -->
            <b-table
              :items="transactionHistory"
              :fields="colfields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              ref="activeUserTable"
              show-empty
            >
            </b-table>
          </div>
          <!-- Pagination and Row Count -->
          <div class="row">
            <!-- Row Count -->
            <div
              class="dataTables_paginate paging_simple_numbers col justify-content-center row-count-cust-cls"
            ></div>
            <!-- Pagination -->
            <div class="row-pagination-cust-cls">
              <ul class="pagination-rounded mb-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="transactionHistory.length"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>
<script>
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../../api/global.env";
import moment from "moment";

export default {
  data() {
    return {
      id: CryptoJS.AES.decrypt(this.$route.params.id, secretKey).toString(
        CryptoJS.enc.Utf8
      ),
      creditsDetails: null,
      updatedCredit: null,
      subscriptionPlanData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      transactionHistory: null,
      orgId: this.$store.state.Auth.orgId,
      colfields: [
        {
          key: "creditsPurchased",
          label: "Credits Purchased",
        },
        {
          key: "previousBalance",
          label: "Previous Balance",
          sortable: false,
        },
        {
          key: "newBalance",
          label: "New Balance",
          sortable: false,
        },

        {
          key: "purchasedAt",
          label: "Purchased At",
          formatter: (value, key, item) =>
            moment(item.purchasedAt).format("DD-MM-YYYY hh:mm A"),
        },
      ],
    };
  },
  mounted() {
    this.creditPointsReport();
    this.getSubscriptionPlans();
    this.transactionHistoryReport();
  },
  computed: {
    rows() {
      return this.transactionHistory?.length;
    },
  },
  watch: {
    perPage: {
      handler() {
        this.currentPage = 1;
      },
    },
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },

    creditPointsReport() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`/orgadmin/credits-info`, { headers: { orgid: this.id } })
        .then((response) => {
          console.log("credits-info: ", response);
          if (response.data) {
            this.creditsDetails = response.data.data;
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "An error occurred while fetching credit details"
          );
        });
    },
    openUpdateCreditModal() {
      this.$refs["updateCreditModel"].show();
    },
    closeUpdateCreditModal() {
      this.updatedCredit = null;
      this.$refs["updateCreditModel"].hide();
    },
    updateCreditsHandler() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      let data = {
        creditId: this.updatedCredit,
        orgId: this.id,
      };
      this.$store.getters.client
        .post(`serveradmin/credit-plans/add`, data)
        .then(() => {
          this.closeUpdateCreditModal();
          this.messageToast(
            "Success",
            "success",
            "Credits has been changed successfully"
          );
          this.creditPointsReport();
          this.transactionHistoryReport();
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "Unable to change the credits plan. Please try again later."
          );
        });
    },
    async getSubscriptionPlans() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get("/serveradmin/credit-plans")
        .then((response) => {
          if (response.data.result) {
            this.subscriptionPlanData = response.data.result;
            this.$store.commit("setSubplanData", this.subscriptionPlanData);
          } else {
            this.messageToast("Error", "warning", "No credits found");
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "warning",
            "An error occurred while fetching credits"
          );
        });
    },
    transactionHistoryReport() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`/serveradmin/organization/creditpurchasehistory?orgId=${this.id}`)
        .then((response) => {
          console.log("trans history ", response);
          if (response.data) {
            this.transactionHistory = response.data.result;
            this.$store.commit(
              "setTransactionHistory",
              this.transactionHistory
            );
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "An error occurred while fetching transaction history"
          );
        });
    },
  },
};
</script>
