<template>
  <b-card>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table
        :filter="filter"
        sticky-header
        :items="admins"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template v-slot:cell(projects)="row" class="text-right">
          <div>
            <ol>
              <template
                v-if="
                  row.item &&
                    row.item.projectName &&
                    row.item.projectName.length > 0
                "
              >
                <li v-for="project in row.item.projectName" :key="project">
                  {{ project }}
                </li>
              </template>
              <template v-else>
                <div>Project not Added</div>
              </template>
            </ol>
          </div>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </b-card>
</template>
<script>
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../../api/global.env";
export default {
  data() {
    return {
      IdForProjects: [],
      filter: "",
      pageOptions: [5, 10, 25, 50, 100],
      fields: [
        {
          key: "email",
          sortable: true,
        },
        {
          key: "githubEmail",
          sortable: true,
        },
        {
          key: "username",
          sortable: true,
        },
        {
          key: "projects",
          sortable: true,
        },
      ],
      currentPage: 1,
      perPage: 5,
      admins: [],
      projectsList: [],
      mergedData: [],
      orgId: CryptoJS.AES.decrypt(this.$route.params.id, secretKey).toString(
        CryptoJS.enc.Utf8
      ),
      id: CryptoJS.AES.decrypt(this.$route.params.id, secretKey).toString(
        CryptoJS.enc.Utf8
      ),
      formData: {
        orgId: "",
        email: "",
        password: "",
        name: "",
        isActive: false,
        contact: "",
        isChangePassword: false,
      },
    };
  },
  mounted() {
    this.getOrganizationAdmin();
    this.getUserProjects();
  },

  methods: {
    async getOrganizationAdmin() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get(`/orguser/byorgid?orgId=${this.id}`, {
          headers: {
            orgId: this.id,
          },
        })
        .then((response) => {
          this.admins = response.data;
          response.data.forEach((element) => {
            this.IdForProjects.push({
              id: element.id,
            });
          });
          this.IdForProjects.forEach((element, index) => {
            this.getUserProjects(element.id, index);
          });
          loader.hide();
        })
        .catch(() => {
          loader.hide();
        });
    },
    async getUserProjects(id, index) {
      await this.$store.getters.client
        .get(`/project/byuserId?userId=${id}`, {
          headers: {
            orgId: this.id,
          },
        })
        .then((response) => {
          this.projectsList = response.data;
          //merge data from both APIs into a single array
          this.mergedData = this.admins.map((admin, i) => {
            if (i === index) {
              return {
                ...admin,
                projectName: response.data.map((ele) => ele.projectName),
              };
            } else {
              return {
                ...admin,
              };
            }
          });
          this.admins = this.mergedData;
        })
        .catch(() => {});
    },
  },
  computed: {
    rows() {
      return this.admins.length;
    },
    paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;
      return this.admins.slice(start, end);
    },
  },
  created() {
    if ("id" in this.$route.params) {
      this.formData.orgId = CryptoJS.AES.decrypt(
        this.$route.params.id,
        "metapercept"
      ).toString(CryptoJS.enc.Utf8);
    }
  },
};
</script>
<style lang="scss">
h3 i,
h3 span,
h4 {
  color: #021559 !important;
  text-transform: uppercase;
  line-height: 1.25;
  margin-bottom: 0;
  font-weight: 600;
}
h4.mb-2 {
  font-weight: bold;
}
.project-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    font-size: 1.25rem;
    font-weight: 600;
    color: #505d69;
    padding: 1rem 0;
    border-bottom: 1px solid #74788d;
  }
}
.page-item.active .page-link {
  background: #006d5b;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.page-item .page-link {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
button#show-btn {
  background-color: #0e0e87;
}
</style>
